<!--
 * @Author: your name
 * @Date: 2021-01-28 19:42:51
 * @LastEditTime: 2021-01-29 14:37:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/FromWorld.vue
-->
<template>
  <div class="companty_con">
    <div
      v-for="(i, index) in $t('WapIndexIntr1.pictures')"
      :key="index"
      class="con_item"
      @click="goCompany(i.name)"
    >
      <img :src="i.img" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goCompany(name) {
      this.$router.push(`/azabord?prev=${name}`);
    }
  }
};
</script>

<style scoped lang="less">
.companty_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px calc(100vw * 7.5 / 192) 0 calc(100vw * 10.5 / 192);
  .con_item {
    background: #c4c4c4;
    width: calc(100vw * 55 / 192);
    margin-right: calc(100vw * 3 / 192);
    // height: calc(100vw * 70 / 192);
    cursor: pointer;
    flex: auto;
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
